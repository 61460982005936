import React from 'react'
import Preeloader from '../../Preeloader'
import Services from './Services'

const Service = () => {
    return (
        <>
            {/* <Preeloader /> */}
            <main>
                <section className='service-list'>
                    <Services />
                </section>
            </main>
        </>
    )
}

export default Service
